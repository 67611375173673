html {
  display: flex;
}
body {
  background-color: #282c34;
  background-image: url('./assets/images/bg.png');
  background-repeat: repeat;
  background-blend-mode: soft-light;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  flex-grow: 1;
}
div#root {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.App {
  width: calc(100vw);
  text-align: center;
}

.App-logo {
  height: 20vmin;
  pointer-events: none;
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

.App-header {
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.App-view {
  width: 100%;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 40px;
  padding-bottom: 40px;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.featured-view {
  max-width: 100%;
  padding-top: 40px;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.App-view a {
  color: white;
}

.Columns {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: flex-start;
}

.filteringContainerDiv {
  /* display: block; */
  padding: 5;
  min-height: 250px;
  height: calc(80vh - 250px);
  /* height: calc(80vh - 200px) !important; */
  /* overflow: auto; */
}

/* .ItemView {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
} */

.TreeItem {
  text-decoration: none;
  /* display: block; */
  height: var(--variable--height);
  height: calc(100% - 10px) !important;
  margin-bottom: 10px;
}

.TreeItem:hover {
  text-decoration: underline;
}

.CardGroup {
  margin-bottom: 10px;
}

.CardTitle:hover {
  text-decoration: underline;
}

.tab-content {
  height: var(--variable--height);
  height: calc(80vh - 250px);
}

.tree-lookup-input.group input {
  width: 70% !important;
}
.tree-group {
  padding: 0.5em 1.0em !important;
  margin-left: 0.5em;
  width: calc(30% - 0.5em) !important;
}

.nav-link:disabled {
  color: #a0a0a0;
}

.nav-link:enabled {
  color: #fff;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
